import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { get, canUseDOM } from '../../lib/getPropertyByPath';
import {getColorByTheme} from "@dtcm/ui-components/dist/utils/index";
import cssVars from 'css-vars-ponyfill';

const useScript = (body: string, shouldRender: boolean) => {
  React.useEffect(() => {
    let script: any;
    //let prefixScript: any;

    if (shouldRender) {
      script = document.createElement('script');
      script.innerHTML = body;

      document.body.appendChild(script);

     /* prefixScript = document.createElement('script');
      prefixScript.innerHTML = 'window.prefix = "dotqa104"';
      document.body.appendChild(prefixScript);*/
    }

    return () => {
      if (shouldRender) {
        document.body.removeChild(script);
      }
    };
  }, [body, shouldRender]);
};

export interface MainContainerProps extends RouteComponentProps {
  context: {
    origin: string;
    language: string;
    tealium: {
      originUrl: string;
      environment: string;
    };
    disablePageIndexing: boolean;
  };
  route: {
    name: string;
    itemId: string;
    templateName: string;
    fields: {
      browserTitle: {
        value: string;
      };
    };
  };
}

const resolvePageTitle = (route: any) => {
  let pageTitle = get(route, 'fields.browserTitle.value');
  if (!pageTitle || pageTitle === '$name') {
    pageTitle = get(route, 'fields.pageTitle.value');
  }
  return pageTitle;
};

const getOriginUrl = (context: any) => {
  let origin = get(context, 'origin');
  if (origin.endsWith('/')) origin += '/';
  return origin;
};

const resolveCanonicalUrl = (context: any, location: any) => {
  const path = location && location.pathname ? location.pathname : '/';
  return `${getOriginUrl(context)}${path}`;
};

// TODO: refactor this because this function is a quick fix for
// <link data-react-helmet="true" rel="alternate" href="https://www.dubaitourism.gov.aehttps://www.dubaitourism.gov.ae/ar" hreflang="ar">
// and where it is used the alternateUrl is "any"
const getAlternateUrl = (context: any, alternateUrl: { url: string }) => {
  const origin = getOriginUrl(context);
  const url = alternateUrl.url;

  if (url.toLowerCase().startsWith(origin.toLowerCase())) {
    return `${origin}${url.substring(origin.length)}`;
  }

  return `${origin}${url}`;
};

const renderProperties = (prefix: string, queryString: string) => {
  if (!queryString) {
    return null;
  }
  if (!prefix) {
    return null;
  }
  const properties = [];
  const parts = queryString.split('&');
  for (let i = 0; i < parts.length; i++) {
    const pair = parts[i].split('=');
    properties.push(
      <meta property={`${prefix}:${decodeURIComponent(pair[0])}`} content={decodeURIComponent(pair[1])} />
    );
  }

  return properties;
};

const getAlternateUrls = (context: any) => {
  const alternateUrls = get(context, 'alternateUrls');
  if (!alternateUrls) {
    return null;
  }
  const alternateUrlTags = alternateUrls.map((alternateUrl: any) => {
    return (
      <link
        key={alternateUrl.lang}
        rel="alternate"
        href={`${getAlternateUrl(context, alternateUrl)}`}
        hrefLang={alternateUrl.lang === 'zh' ? 'zh-hans' : alternateUrl.lang}
      />
    );
  });
  // alternateUrlTags.push(
  //   <link rel="alternate" href={resolveCanonicalUrl(context, location)} hrefLang="x-default" />
  // );
  return alternateUrlTags;
};

const getXDefault = (context: any) => {
  const alternateUrls = get(context, 'alternateUrls');
  if (!alternateUrls) {
    return null;
  }
  const alternateUrlTags = alternateUrls.map((alternateUrl: any) => {
    if (alternateUrl.lang === 'en') {
      return <link key={alternateUrl.lang} rel="alternate" href={`${getAlternateUrl(context, alternateUrl)}`} hrefLang="x-default" />;
    } else {
      return '';
    }
  });

  return alternateUrlTags;
};

const isInViewport = (el: any) => {
  if (!el) {
    return false;
  }
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};

const MainContainer: React.FunctionComponent<MainContainerProps> = ({ route, location, context }) => {
  const pageTitle = resolvePageTitle(route);
  const pageDescription = get(route, 'fields.metaDescription.value');
  const pageUrl = resolveCanonicalUrl(context, location);
  const ogTitle = get(route, 'fields.og-title.value');
  const ogDescription = get(route, 'fields.og-description.value');
  const ogImage = get(route, 'fields.og-image.value.src');
  const twitterTitle = get(route, 'fields.twitter-title.value');
  const twitterDescription = get(route, 'fields.twitter-description.value');

  const { itemId,  templateName } = route;
  const { language, tealium, disablePageIndexing } = context;

  const browserLanguage = typeof navigator !== 'undefined' ? navigator.language : '';

  const utag_data = {
    page_id: itemId,
    page_name: templateName,
    page_language: language,
    template: templateName,
    pillar: get(route, 'fields.pillar.value'),
    content_type: get(route, 'fields.contentType.value'),
    persona: get(route, 'fields.persona.value'),
    page_location: pageUrl,
    page_title: pageTitle,
    page_path: location.pathname,
    page_browser_language: browserLanguage,
  };

  if (!tealium) {
    console.warn('tealium settings are not provided');
  }
  const [firstLoad, setFirstLoad] = React.useState<Boolean>(true);
  const originUrl = get(tealium, 'originUrl', '');
  const environment = get(tealium, 'environment', '');
  const tealiumOrigin = originUrl.endsWith('/') ? originUrl.slice(0, -1) : originUrl;
  const tealiumScriptBody = `(function(a,b,c,d){a="${tealiumOrigin}/dubaitourism.gov.ae/${environment}/utag.js";b=document;c="script";d=b.createElement(c);d.src=a;d.type="text/java"+c;d.async=true;a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);})();`;
  // usejQuery(tealiumScriptBody, false);
  useScript(tealiumScriptBody, true);
  useEffect(() => {
    const wind: any = window;
    if (wind) {
      wind.utag_data = {
        ...wind.utag_data,
        ...utag_data,
      };
    }
  }, []);
  useEffect(() => {
    const wind: any = window;
    const utag = wind.utag;

    if (wind && utag) {
      utag.data = {
        ...utag.data,
        ...utag_data,
      };
      wind.utag_data = {
        ...wind.utag_data,
        ...utag_data,
      };

      wind.utag.utag_cfg_ovrd = wind.utag.utag_cfg_ovrd || {}; //Make sure we don't kill a previous decleration of the object
      wind.utag.utag_cfg_ovrd.noview = true;
      if(!firstLoad) {
        utag.view(wind.utag_data);

      } else {
        setFirstLoad(false)

      }
    }
  }, [route.name]);

  useEffect(() => {

    const placeholder = get(route, 'placeholders.dot-root', []);
    const placeholderName = get(placeholder[0], `params.bodyPlaceholderName`);

    const moduels = get(placeholder[0], `placeholders.${placeholderName}`)
    let scrolledto25 = false;
    let scrollTimer: any = 0;
    let scrollTimer2: any = 0;
    let timer: any = 0;

    let timerLogged = false;
    let timerLogged2 = false;
    let scrolledto50 = false;
    let scrolledto75 = false;
    let scrolledto100 = false;

    let timer20Finsihed = false;
    let timer50Finsihed = false;
    const desktopHeights: any = {
      ContactCard: {
        desktop: 740,
        mobile: 991,
      },

      Tags: {
        desktop: 140,
        mobile: 178,
      },

      MediaGrid: {
        desktop: 1000,
        mobile: 2040,
      },
      Footer: {
        desktop: 600,
        mobile: 1050,
      },

      DetailsCardService: {
        desktop: 369,
        mobile: 585,
      },
      MediaVideo: {
        desktop: 740,
        mobile: 350,
      },

      MediaCarousel: {
        desktop: 795,
        mobile: 380,
      },
      SocialSharing: {
        desktop: 126,
        mobile: 146,
      },
      TestimonialMultipleMedia: {
        desktop: 414,
        mobile: 663,
      },
      TestimonialMedia: {
        desktop: 414,
        mobile: 663,
      },
      WizardTraining: {
        desktop: 654,
        mobile: 562,
      },
      DownloadSingle: {
        desktop: 242,
        mobile: 231,
      },
      AutoDownload: {
        desktop: 104,
        mobile: 104,
      },

      MediaAndCopy: {
        desktop: 456,
        mobile: 677,
      },

      BottomNavigation: {
        desktop: 224,
        mobile: 288,
      },

      UserGeneratedContent: {
        desktop: 358,
        mobile: 358,
      },

      GeneralCardIcon: {
        desktop: 335,
        mobile: 315,
      },
      GeneralCardIconMultiple: {
        desktop: 494,
        mobile: 365,
      },
      GeneralCardImage: {
        desktop: 554,
        mobile: 571,
      },
      GeneralCardImageMultiple: {
        desktop: 738,
        mobile: 751,
      },

      AccordionFAQ: {
        desktop: 400,
        mobile: 358,
      },
      BannerSmall: {
        desktop: 456,
        mobile: 360,
      },
      ServicesHeader: {
        desktop: 655,
        mobile: 550,
      },
      Tabs: {
        desktop: 558,
        mobile: 550,
      },
      EloquaForm: {
        desktop: 770,
        mobile: 820,
      },
      DynamicListingComponent: {
        desktop: 1740,
        mobile: 3700,
      },
    };
    let scrollEvent: any = undefined;

    if (canUseDOM) {
      const deviceType = document.body.clientWidth <= 767 ? 'mobile' : 'desktop';
      timer = setTimeout(() => {
        let bodyHeightOnLoad = document.body.clientHeight + desktopHeights.Footer[deviceType];
        moduels && moduels.map((module: any) => {
          if (moduels.length === 1) {
            bodyHeightOnLoad = document.body.clientHeight;
          }

          if (desktopHeights[module.componentName]) {
            bodyHeightOnLoad += desktopHeights[module.componentName][deviceType];
          }
        });
        console.warn('final body height', bodyHeightOnLoad);
        const wind: any = window;
        if (wind) {
          wind.utag_data = {
            ...wind.utag_data,
            ...utag_data,
            total_window_height: `${bodyHeightOnLoad}`,
          };
        }
        clearTimeout(scrollTimer);
        clearTimeout(scrollTimer2);
        let scrollPercentRounded = 0;

        scrollEvent = () => {
          let scrollTop = window.scrollY;
          let docHeight = bodyHeightOnLoad;
          let winHeight = window.innerHeight;
          let scrollPercent = scrollTop / (docHeight - winHeight);
          scrollPercentRounded = Math.round(scrollPercent * 100);
          console.warn('scrollPercentRounded', scrollPercentRounded);
          if (scrollPercentRounded >= 25) {
            if (!scrolledto25) {
              scrolledto25 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('25');
              }
              if (timer20Finsihed) {
                if (get(window, 'SC_Scoll_Time_Function1')) {
                  get(window, 'SC_Scoll_Time_Function1')(scrollPercentRounded, '20');
                }
              }
            }
          }
          if (scrollPercentRounded >= 50) {
            if (!scrolledto50) {
              scrolledto50 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('50');
              }

              if (timer50Finsihed) {
                if (get(window, 'SC_Scoll_Time_Function1')) {
                  get(window, 'SC_Scoll_Time_Function1')(
                    scrollPercentRounded > 100 ? 100 : scrollPercentRounded,
                    '30'
                  );
                }
              }

              //   clearTimeout(scrollTimer);
            }
          }
          if (scrollPercentRounded >= 75) {
            if (!scrolledto75) {
              scrolledto75 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('75');
              }
            }
          }

          if (isInViewport(document.querySelector('.footer__bottom'))) {
            if (!scrolledto100) {
              scrolledto100 = true;

              if (get(window, 'SC_Scoll_Function')) {
                get(window, 'SC_Scoll_Function')('100');
              }
            }
          }
        };

        scrollTimer = setTimeout(() => {
          timer20Finsihed = true;
          if (!timerLogged) {
            if (scrolledto25) {
              timer20Finsihed = false;
              if (get(window, 'SC_Scoll_Time_Function1')) {
                get(window, 'SC_Scoll_Time_Function1')(scrollPercentRounded, '20');
              }

              timerLogged = true;
              clearTimeout(scrollTimer);
            }
          } else {
            clearTimeout(scrollTimer);
          }
        }, 20 * 1000);
        scrollTimer2 = setTimeout(() => {
          timer50Finsihed = true;
          if (!timerLogged2) {
            if (scrolledto50) {
              timer50Finsihed = false;
              if (get(window, 'SC_Scoll_Time_Function1')) {
                get(window, 'SC_Scoll_Time_Function1')(
                  scrollPercentRounded > 100 ? 100 : scrollPercentRounded,
                  '30'
                );
              }
              timerLogged2 = true;
              clearTimeout(scrollTimer2);
            }
          } else {
            clearTimeout(scrollTimer2);
          }
        }, 30 * 1000);
        window.addEventListener('scroll', scrollEvent);
      }, 3000);
    }
    return () => {
      window.removeEventListener('scroll', scrollEvent);
      clearTimeout(timer);

      clearTimeout(scrollTimer);
      clearTimeout(scrollTimer2);
    };
  }, [route.name]);
  cssVars({
      shadowDOM: true,
      variables: {
          '--site-color': '#005F66',
          '--site-color-2': '#005F66',
          '--main-color': '#005F66',
          '--main-color--active': '#115E67',
          '--main-color-text': `${getColorByTheme('#005F66')}`,
          '--main-color--active-2': '#115E67',
          '--site-active-color': '#115E67',
          '--greyscale': '#505050',
          '--site-font-family': `'Dubai', 'Arial', sans-serif`
      }
  });

  const anyRoot: any = route;

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={pageUrl} />
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={get(route, 'fields.metaKeywords.value')} />
        {disablePageIndexing ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content={get(route, 'fields.metaRobots.value')} />
        )}
        {/* <meta name="robots" content="index, follow" /> */}
        <meta property="og:title" content={ogTitle ? ogTitle : pageTitle} />
        <meta property="og:description" content={ogDescription ? ogDescription : pageDescription} />
        <meta property="og:type" content={get(route, 'fields.og-type.value', 'website')} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={pageUrl} />

        <meta name="twitter:title" content={twitterTitle ? twitterTitle : pageTitle} />
        <meta
          name="twitter:description"
          content={twitterDescription ? twitterDescription : pageDescription}
        />
        <meta name="twitter:card" content={get(route, 'fields.twitter-card.value', 'summary')} />
        <meta name="twitter:site" content={get(route, 'fields.twitter-site.value', '@visitdubai')} />
        <meta name="twitter:image" content={get(route, 'fields.twitter-image.value.src', ogImage)} />

        {renderProperties('fb', get(route, 'fields.facebook-properties.value'))}
        {renderProperties('twitter', get(route, 'fields.twitter-properties.value'))}
        {getAlternateUrls(context)}
        {getXDefault(context)}

        <script type="text/javascript">{`var utag_data=${JSON.stringify(utag_data)}
        var algoliaSettings={key:"${get(context, 'configuration.algolia.algoliaApiKey')}",id:"${get(
          context,
          'configuration.algolia.algoliaApplicationId'
        )}",fullTextSearchIndex:"${get(context, 'configuration.algolia.fullTextSearchIndex')}"}`}</script>
      </Helmet>
      <Placeholder name="dot-root" rendering={anyRoot} route={route} />
      <style>
        {`:root {
          --site-color: #005F66;
          --site-color-2: #005F66;
          --main-color: #005F66;
          --main-color--active: #003D41;
          --main-color--active-2: #003D41;
          --secondary-color: #949494;
          --secondary-color--active: #858585;
          --tertiary-first: #CB3F8F;
          --tertiary-second: #0067B9;
          --tertiary-third: #4D3F7F;
          --greyscale: #505050;
        }`}
      </style>
    </React.Fragment>
  );
};

export default withRouter(MainContainer);
