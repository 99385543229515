import { ContrastSwitchOption } from '@dtcm/ui-components';

export interface State {
  selectedFontSize: FontSize;
  selectedContrastValue: string;
}

export interface Action {
  type: string;
  payload: any;
  error?: Error;
}

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'accessibility-settings-initialized': {
      return state;
    }
    case 'font-size-changed': {
      return { ...state, selectedFontSize: action.payload };
    }
    case 'contrast-value-changed': {
      return { ...state, selectedContrastValue: action.payload };
    }
    default: {
      return state;
    }
  }
}

// This function is a bit superfluous, but allows us to centralize the actions
// associated with adjusting accessibility settings.
export const initializeAccessibilitySettings: InitializeAccessibilitySettingsFunction = (values) => {
  const { contrast, fontSize } = values;
  return (dispatch: any) => {
    setContrast({ theme: { value: contrast }, title: { value: '' } });
    setFontSize(fontSize);
    dispatch({
      type: 'accessibility-settings-initialized',
    });
  };
};

interface initializeAccessibilityArgsType {contrast: string; fontSize: FontSize;}

export type InitializeAccessibilitySettingsFunction = (values: initializeAccessibilityArgsType) => void;

export type FontSize = 'z100' | 'z150' | 'z200';

export const fontSizeClasses = ['z100', 'z150', 'z200'];

export type ChangeFontSizeFunction = (
  fontSize: FontSize
) => (dispatch: any, getState?: any) => Promise<any> | void;

export const changeFontSize: ChangeFontSizeFunction = (fontSize: FontSize) => {
  return (dispatch: any) => {
    setFontSize(fontSize);

    dispatch({
      type: 'font-size-changed',
      payload: fontSize,
    });
  };
};

const setFontSize = (fontSize: FontSize) => {
  if (typeof window !== 'undefined') {
    const htmlDocTag = document.querySelector('html');
    if(htmlDocTag) {
      htmlDocTag.classList.remove(...fontSizeClasses);
      htmlDocTag.classList.add(fontSize);
    }

    storageManager.setFontSize(fontSize);
  }
};

export type ChangeContrastFunction = (
  option: ContrastSwitchOption
) => (dispatch: any, getState?: any) => Promise<any> | void;

export const changeContrast: ChangeContrastFunction = (option: ContrastSwitchOption) => {
  return (dispatch: any) => {
    setContrast(option);
    dispatch({
      type: 'contrast-value-changed',
      payload: option.theme.value,
    });
  };
};

const setContrast = (option: ContrastSwitchOption) => {
  if (typeof window !== 'undefined') {
    const bodyDocTag = document.querySelector('body');
    if(bodyDocTag && option.theme.value === 'theme1') {
      bodyDocTag.classList.remove('contrast');
    }

    if(bodyDocTag && option.theme.value === 'theme2') {
      bodyDocTag.classList.add('contrast');
    }
  }

  storageManager.setContrast(option.theme.value);
};

export function resolveAccessibilitySettings(config: any) {
  return {
    contrast: storageManager.getContrast() || config.defaultContrast,
    fontSize: storageManager.getFontSize() || config.defaultFontSize,
  };
}

export const storageManager = {
  getFontSize: () => getItem('dtcm-font-size'),
  setFontSize: (value: string) => setItem('dtcm-font-size', value),
  getContrast: () => getItem('dtcm-contrast'),
  setContrast: (value: string) => setItem('dtcm-contrast', value),
};

function getItem(key: string) {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key);
  }
  return null;
}

function setItem(key: string, value: any) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, value);
  }
}
