import React from 'react';
import { useAppStateContext } from '../../lib/AppStateProvider';
import { Error } from './Error';
import { NotFound } from './NotFound';
import MainContainer from './MainContainer';

export const LayoutFactory: React.FC = () => {
  const { appState } = useAppStateContext();

  const { routeData, routeError } = appState;

  if (routeError) {
    return <Error error={routeError} />;
  }
  if (routeData) {
    return <MainContainer route={routeData} context={appState.sitecoreContextData} />;
  }

  // no route data for the current route in Sitecore - show not found component.
  // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
  // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
  return <NotFound context={appState.sitecoreContextData} />;
}
